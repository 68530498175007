import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const modules = [
  {
    path: '',
    loadChildren: () => import('./main/main.module').then(m => m.MainModule),
  },
  {
    path: 'about',
    loadChildren: () => import('./about/about.module').then(m => m.AboutModule),
  },
  {
    path: 'contact',
    loadChildren: () => import('./contact/contact.module').then(m => m.ContactModule),
  },
  {
    path: 'cal',
    loadChildren: () => import('./cal/cal.module').then(m => m.CalModule),
  },
  {
    path: 'cart',
    loadChildren: () => import('./cart/cart.module').then(m => m.CartModule),
  },
  {
    path: 'jobs',
    loadChildren: () => import('./jobs/jobs.module').then(m => m.JobsModule),
  },
  {
    path: 'main',
    loadChildren: () => import('./main/main.module').then(m => m.MainModule),
  },
  {
    path: 'maps',
    loadChildren: () => import('./maps/maps.module').then(m => m.MapsModule),
  },
  {
    path: 'page',
    loadChildren: () => import('./page/page.module').then(m => m.PageModule),
  },
  {
    path: 'photo',
    loadChildren: () => import('./photo/photo.module').then(m => m.PhotoModule),
  },
  {
    path: 'team',
    loadChildren: () => import('./team/team.module').then(m => m.TeamModule),
  },
  {
    path: 'video',
    loadChildren: () => import('./video/video.module').then(m => m.VideoModule),
  },
  {
    path: 'work',
    loadChildren: () => import('./work/work.module').then(m => m.WorkModule),
  },
  // {
  //   path: '',
  //   redirectTo: '/de/main',
  //   pathMatch: 'full'
  // },
]

const routes: Routes = [
  {
    path: 'de',
    data: { language: 'de', animation: '*' },
    children: modules
  },
  {
    path: 'en',
    data: { language: 'en' },
    children: modules
  },
  {
    path: 'ru',
    data: { language: 'ru' },
    children: modules
  },
  {
    path: '',
    redirectTo: '/de/main',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: '/de/main',
    pathMatch: 'full'
  } // + error 404
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    { preloadingStrategy: PreloadAllModules,
      // initialNavigation: 'enabled'
     }
  )], // add preload spk
  exports: [RouterModule]
})
export class AppRoutingModule { }
