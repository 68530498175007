import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ServiceWorkerModule } from '@angular/service-worker';

import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
// import { provideAuth, getAuth } from '@angular/fire/auth';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getAnalytics, provideAnalytics } from '@angular/fire/analytics';
import { CustomProvider, initializeAppCheck, provideAppCheck, ReCaptchaV3Provider } from '@angular/fire/app-check';
import { getPerformance, providePerformance } from '@angular/fire/performance';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { environment } from '../environments/environment';
import { NaviModule } from './navi/navi.module';
import { NgModule } from '@angular/core';



const isNode = () => typeof process !== 'undefined' && process.versions?.node;

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    // Custom modules
    NaviModule,
    // PWA Service Worker
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [
    // AngularFire
      provideFirebaseApp(() => initializeApp(environment.firebase)),
      // provideAuth(() => getAuth()),
      // provideAuth(() => {
      // const auth: Auth = initializeAuth(getApp(), {
      //   persistence: [
      //     indexedDBLocalPersistence,
      //     browserLocalPersistence,
      //     browserSessionPersistence,
      //   ],
      //   popupRedirectResolver: undefined,
      // });
      //   return auth;
      // }),
      provideStorage(() => getStorage()),
      provideFirestore(() => getFirestore()),
      provideAnalytics(() => getAnalytics()),
      providePerformance(() => getPerformance()),
      provideAppCheck(() => {
        const provider = isNode() ?
          new CustomProvider({ getToken: () => Promise.reject() }) :
          new ReCaptchaV3Provider(environment.recaptcha);
        return initializeAppCheck(undefined,
          { provider, isTokenAutoRefreshEnabled: true });
      }),
    // UserTrackingService,
    // ScreenTrackingService,
    // useValue: environment.firebase,
  ],
  bootstrap: [
    AppComponent,
  ]
})
export class AppModule { }
