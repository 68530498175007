import { Component, OnInit, inject, signal } from '@angular/core';
import { Observable, of } from 'rxjs';

import { MenuItems, Navigation } from '../types';
import { CommonService } from '../share/common.service';
// import { Router } from '@angular/router';


@Component({
  selector: 'app-navi',
  template: `
  <lib-md-navi [menu$]=menu$ [list$]=list$ (langEvent)="changeLanguage($event)"></lib-md-navi>
  `,
})


export class NaviComponent implements OnInit {
  list$: Observable<Navigation> = of();
  menu$: Observable<MenuItems> = of(
    {
      "lang": [
        {
          "link": "/de/main",
          "lang": "de",
          "label": "DE Deutsch"
        },
        {
          "link": "/en/main",
          "lang": "en",
          "label": "EN English"
        },
        {
          "link": "/ru/main",
          "lang": "ru",
          "label": "RU Русский"
        },
      ]
    }
  );

  private commonService = inject(CommonService);
  private lang = signal('');

  ngOnInit(): void {
    this.changeLanguage('de');
  }

  changeLanguage(language: string): void {
    this.list$ = this.commonService.fsArticle(language, 'navi');
    // console.log('navi', language);

    this.commonService.bsInit(language);

    this.lang.set(language);
    console.log('The lang is: ' + this.lang());


  }

}
