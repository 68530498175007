import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NaviComponent } from './navi.component';

import { MdcLibModule } from 'lib-mdc';


@NgModule({
  declarations: [
    NaviComponent,
  ],
  imports: [
    CommonModule,
    MdcLibModule,
  ],
  exports: [
    NaviComponent,
  ]
})
export class NaviModule { }
