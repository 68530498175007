// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyAVHyRKF45L10MNb7_1MG1UJE_4IAmKwCs",
    authDomain: "jgs-dsn.firebaseapp.com",
    projectId: "jgs-dsn",
    storageBucket: "jgs-dsn.appspot.com",
    messagingSenderId: "271105228589",
    appId: "1:271105228589:web:43d766668960832e4e1da4",
    measurementId: "G-VPF8VV6SFF",
  },
  recaptcha: '6LePBEchAAAAAJNDc9fUCoVjW5IBOk4AcUm-BGZ0'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
